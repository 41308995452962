import { z } from 'zod';

export const deleteEmbeddingPathSchema = z.object({
  id: z.string().min(1),
});

export const deleteEmbeddingInputSchema = deleteEmbeddingPathSchema;

export const deleteEmbeddingOutputSchema = z.enum(['acknowledged', 'completed']);

export type DeleteEmbeddingInput = z.infer<typeof deleteEmbeddingInputSchema>;
export type DeleteEmbeddingOutput = z.infer<typeof deleteEmbeddingOutputSchema>;
