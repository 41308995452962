import { upsertEmbeddingInputSchema, upsertEmbeddingOutputSchema } from '@zealy/schemas';

import { mutation } from '../../utils';

export const upsertEmbedding = mutation({
  method: 'POST',
  path: '/embedding',
  body: upsertEmbeddingInputSchema,
  responses: {
    200: upsertEmbeddingOutputSchema,
  },
});
