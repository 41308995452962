import { router } from '../../utils';
import { deleteEmbedding } from './deleteEmbedding';
import { upsertEmbedding } from './upsertEmbedding';
import { vectorStoreSearch } from './vectorStoreSearch';

export const aiCommunityManagerContract = router(
  {
    vectorStoreSearch,
    upsertEmbedding,
    deleteEmbedding,
  },
  {
    pathPrefix: '/ai-community-manager',
  },
);
