import { z } from 'zod';

export const upsertEmbeddingBodySchema = z.object({
  question: z.string(),
  answer: z.string(),
  id: z.string().min(1).optional(),
});

export const upsertEmbeddingInputSchema = upsertEmbeddingBodySchema;

export const upsertEmbeddingOutputSchema = z.object({
  status: z.enum(['acknowledged', 'completed']),
  id: z.string(),
});

export type UpsertEmbeddingInput = z.infer<typeof upsertEmbeddingInputSchema>;
export type UpsertEmbeddingOutput = z.infer<typeof upsertEmbeddingOutputSchema>;
