import { deleteEmbeddingInputSchema, deleteEmbeddingOutputSchema } from '@zealy/schemas';

import {mutation, noBody} from '../../utils';

export const deleteEmbedding = mutation({
  method: 'DELETE',
  path: '/embedding/:id',
  body: noBody(),
  pathParams: deleteEmbeddingInputSchema,
  responses: {
    200: deleteEmbeddingOutputSchema,
  },
});
